
export const homeObjOne = {
  id: 'oferta',
  lightBg: false,
  LightText: false,
  lightTextDesc: false,
  topLine: 'Strony Wizytówki',
  headline: 'Strona wizytówka - wizualna tożsamość Twojej firmy',
  description: 'Idealne rozwiązanie dla przedsiębiorców i osób prywatnych, pragnących skutecznie i ekonomicznie zaistnieć w cyfrowym świecie. \n\n CENA PAKIETU: 1500zł netto' , 
  buttonLabel: 'Zamów Teraz',
  imgStart: false, 
  img: require('../../images/svg-1.svg').default,
  alt: 'Strony Wizytówki',
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjTwo = {
  id: 'sklepy-internetowe',
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'E-commerce',
  headline: 'Twój spersonalizowany sklep internetowy ',
  description: 'Naszym celem jest stworzenie sklepu online, który nie tylko prezentuje Twoje produkty, ale także przekonuje klientów do dokonywania zakupów. Nasze projekty są graficznie dostosowane do Twojego asortymentu, zapewniając intuicyjne i przyjemne doświadczenie użytkownika. \n\n CENA PAKIETU: od 2500zł netto',
  buttonLabel: 'Zamów Teraz',
  imgStart: true, 
  img: require('../../images/svg-2.svg').default,
  alt: 'E-commerce',
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjThree = {
  id: 'kontakt',
  lightBg: false,
  LightText: false,
  lightTextDesc: false,
  topLine: 'MASZ PYTANIA LUB JESTEŚ GOTÓW NA ROZPOCZĘCIE WSPÓŁPRACY?',
  headline: 'Skontaktuj się z zespołem storkdesign',
  description: "Zadzwoń: +48 881 185 247 \n Napisz do nas: kontakt@storkdesign.pl \n lub wypełnij nasz formularz kontaktowy" ,
  buttonLabel: 'Formularz Kontaktowy',
  imgStart: false, 
  img: require('../../images/svg-3.svg').default,
  alt: 'Kontakt',
  dark: true,
  primary: true,
  darkText: true,
};