import styled from "styled-components";
import {BsTelephoneFill} from 'react-icons/bs'
import {GoMail} from 'react-icons/go'

export const FormContainer = styled.div `
width: 100%;
background: #fff;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
color: #fff;
@media screen and (max-width: 768px) {
  padding: 25px;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin: 25px 0px;
  input, textarea {
    width: 50%;
    margin: 10px;
    color: #000;
    background-color: transparent;
    border: 1px solid #F4F4F4;
    border-bottom: 2px solid #229d95;
    padding: 15px;
    text-align: left;
    @media screen and (max-width: 768px) {
    width: 100%;
    }
    &:focus {
      border: 1px solid #229d95;
    }
    &::placeholder {
      color: #080808;
    }

  }
  input[name='subject'] {
    width: 100%;
  }
  textarea {
    width: 100%;
  }
  input[type='submit'] {
    border: 2px solid #229d95;
    border-radius: 5px;
    margin-top: 15px;
    background-color: #229d95;
    transition: all 0.5s ease-in-out;
    width: 50%;
    border-radius: 25px;
    height: 50px;
    text-align: center;
    color: #fff;
    &:hover {
      background-color: #00757d;
      border: 2px solid #00757d;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
    }
  }
}
`

export const SubmitMessage = styled.div`
background-color: #229d95;
padding: 25px;
transition: all 0.5s ease-in-out;
&.hidden {
  display: none;
  transition: all 0.5s ease-in-out;
}
&.show {
  display: block;
  transition: all 0.5s ease-in-out;
}

`
export const SubmitErrorMessage = styled.div`
background-color: #ff0000;
padding: 25px;
transition: all 0.5s ease-in-out;
&.hidden {
  display: none;
  transition: all 0.5s ease-in-out;
}
&.show {
  display: block;
  transition: all 0.5s ease-in-out;
}

`
export const FormFlex = styled.div`
  display: grid;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  margin: 160px 0px 100px 0px;
  justify-content: center;
`
export const FormFlexLeft = styled.div`
    grid-area: col1;
    max-width: 540px;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: start;
  color: #000;
  text-align: right;
  padding-right: 50px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    text-align: center;
    padding-bottom: 25px;
  }
`
export const FormFlexRight = styled.div`
    grid-area: col2;
    width: 540px;
  padding-top: 0;
  padding-bottom: 0;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: auto;
  }
`
export const FormGrid = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto,1fr);
  align-items: center;
  grid-template-areas: 'col1 col2';
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`
export const GmailIcon = styled(GoMail)`
  margin-left: 8px;
  font-size: 16px;
  `

export const TeleIcon = styled(BsTelephoneFill)`
  margin-left: 8px;
  font-size: 14px;
  `

export const FormRowContainer = styled.div `
width: 100%;
display: flex;
justify-content: space-between;
@media screen and (max-width: 768px) {
  flex-direction: column;
}
`